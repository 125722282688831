import { LanguageDataMap, AvailableLanguage } from './language';
import { MediaInfo } from './media';
import {
  ConceptMovieGF,
  Hyperlink,
  ReservationCenter,
  SpecialPagePriority,
} from './';
import {
  BannerLink,
  GeneralLogo,
  GeneralLogoThemeMap,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

const SECTION_ID = ['header', 'features', 'policy', 'hotels', 'news'] as const;
export type sectionId = typeof SECTION_ID[number];

/**
 * 各サブブランドのマップ初期中央配置施設
 * 星のや 軽井沢, 界 松本, リゾナーレ 八ヶ岳, OMO 浅草, BEB 軽井沢, そのほかの個性的な施設 磐梯山
 */
export const INITIAL_DISPLAY_HOTELS = {
  hoshinoya: '0000000001',
  kai: '0000000104',
  risonare: '0000000003',
  omo: '0000000316',
  beb: '0000000202',
  uniquehotels: '0000000004',
} as const;

export type initialDisplayHotel = keyof typeof INITIAL_DISPLAY_HOTELS;

export interface HotelBrandBasicInfo {
  id: string;
  slug: string;
  /**
   * 施設横断検索ID
   * これがある場合は横断検索ページへ遷移する際にブランドを絞り込んだURLで遷移する
   * （例）
   * https://hoshinoresorts.com/crosssearch/JA/?brands=500
   */
  crosssearchId?: string | null;
  name: string;
  /** 外部ブランドサイトURL */
  url?: string;
  /** 外部ブランドサイトURLの有効言語リスト */
  availableLanguageUrl?: LanguageDataMap<string>;
  urlText?: string;

  /**
   * ブランドに紐付けされた施設のリスト
   *
   * 並び順は保証するけど、このブランドに属する施設を正確に保証している訳ではありません。
   * なぜなら、施設の新規作成や更新や削除の時にこのフィールドを書き換える事にしているけども、
   * それが行われていない可能性があるから。
   * なのでブランドに所属する施設一覧を表示したい時、
   * ブランドIDで施設を全て検索した上でこのフィールドを並び替えのためだけに使用してね。
   */
  linkedHotels?: { slug: string }[];

  /**
   * サイト名
   */
  siteName: string | null;

  /**
   * ページ概要
   */
  metaDescription: string | null;

  /**
   * OGP画像
   */
  ogImage: string | null;
  /**
   * ブランドバナー
   * - 🍔メニューに表示
   */
  banners: BrandBanner[];
  /**
   * ブランドロゴ
   */
  logo?: {
    [K in keyof GeneralLogo]: {
      [T in keyof GeneralLogoThemeMap]: string;
    };
  };
}

export interface HotelBrandInfo extends HotelBrandBasicInfo {
  theme: string | null;
  favicon: string | null;
  reservationCenterId: string | null;
  reservationCenter: ReservationCenter | null;
  reservationCenterNotice: string | null;
}
/**
 * GFフロントで表示するバナー
 * @remarks 表示言語に紐づくプロパティ
 */
export interface BrandBanner
  extends Omit<BannerLink, 'title' | 'link' | 'image'> {
  title: string;
  link: Hyperlink | null;
  image: MediaInfo | null;
}

export function extractBrandBannerLS(
  ls: BannerLink,
  lang: AvailableLanguage,
): BrandBanner {
  const { title, link, image } = ls;
  const langLink = link && link[lang];
  const extractBrandBannerLink = langLink && langLink.url ? langLink : null;

  const langImage = image && image[lang];
  const extractBrandBannerImage =
    langImage && langImage.public_id ? langImage : null;

  return {
    ...ls,
    title: (title && title[lang]) || '',
    link: extractBrandBannerLink!,
    image: extractBrandBannerImage!,
  };
}

/**
 * CMS側で画像とリンクURLが登録されているブランドバナーのみ返却
 * @param brandBannerLinks ブランドバナー
 * @param lang 選択言語
 * @returns 正規化済みのブランドバナー
 */
export function normalizeBrandBanner(
  brandBannerLinks: BannerLink[],
  lang: AvailableLanguage,
): BrandBanner[] {
  return brandBannerLinks
    .map((banner) => extractBrandBannerLS(banner, lang))
    .filter(({ link, image }) => link && image);
}

export interface HotelBrandPolicyItem {
  image: {
    url: string;
    alt: string;
  };
  title: string;
  subTitle: string;
  items: string[];
}

export interface HotelBrandPolicyItemLS
  extends Omit<HotelBrandPolicyItem, 'image' | 'title' | 'subTitle' | 'items'> {
  image: LanguageDataMap<{
    url: string;
    alt: string;
  }>;
  title: LanguageDataMap<string>;
  subTitle: LanguageDataMap<string>;
  items: LanguageDataMap<string>[];
}

export function extractHotelBrandPolicyItemLS(
  ls: HotelBrandPolicyItemLS,
  lang: AvailableLanguage,
): HotelBrandPolicyItem {
  const { image, title, subTitle, items } = ls;
  return {
    ...ls,
    image: image[lang] as {
      url: string;
      alt: string;
    },
    title: title[lang] as string,
    subTitle: subTitle[lang] as string,
    items: items.map((item) => item[lang] as string).filter((row) => !!row),
  };
}

export interface HotelBrandPolicy {
  title: string;
  description: string;
  items: HotelBrandPolicyItem[];
}

export interface HotelBrandPolicyLS
  extends Omit<HotelBrandPolicy, 'title' | 'description' | 'items'> {
  title: LanguageDataMap<string>;
  description: LanguageDataMap<string>;
  items: HotelBrandPolicyItemLS[];
}

export interface BrandConceptMovie extends ConceptMovieGF {}

export function extractHotelBrandPolicyLS(
  ls: HotelBrandPolicyLS,
  lang: AvailableLanguage,
): HotelBrandPolicy {
  const { title, description, items } = ls;
  return {
    ...ls,
    title: title[lang] as string,
    description: description[lang] as string,
    items: items.map((item) => extractHotelBrandPolicyItemLS(item, lang)),
  };
}

export interface HotelBrandDetailInfo {
  __preview?: boolean;

  /**
   * サイトメタ情報
   *
   * * ブランド基本情報のものを引き継ぐのでここにあるのにあまり意味はない
   * * CMS結合する前のハードコードの名残り
   */
  meta: {
    /**
     * サイト名
     */
    siteName: string;

    /**
     * サイト概要
     */
    description?: string;

    /**
     * サイトイメージ画像
     */
    image?: string;
  };

  /**
   * ヘッダーロゴ有無
   * - スクロール時にヘッダー画像を差し替えたい場合にtrueを設定
   */
  useHeaderLogo: boolean;

  /**
   * メインロゴ
   *
   * * このフィールド、コンポーネントのハードコードに移した方が良いと思う。そうすればハードコードデータを全部削除できる
   */
  mainLogo: {
    url: string;
    sprite?: {
      url: string;
      width: number;
      height: number;
      webp?: boolean;
      fallback?: string;
      fps?: number;
      duration?: number;
    };
  };

  /**
   * キャッチコピー
   *
   * KVの上に重ねて表示するようなどかーんとしたキャッチコピー
   *
   * @example "ご当地の魅力に出会える温泉旅館"
   */
  catchCopy: string;

  /**
   * キービジュアル
   */
  keyVisual: {
    /**
     * 画像URL
     */
    image: string;

    /**
     * 画像キャプション
     */
    alt: string;
  };

  /**
   * ブランドのイメージを伝えるタグライン文章
   */
  lead: string;

  /**
   * ブランドコンセプトムービー
   */
  conceptMovie: BrandConceptMovie | null;

  /**
   * ブランドの特色
   */
  features: {
    /**
     * 特色全体の見出し
     */
    title: string;

    /**
     * 特色リスト
     */
    items: {
      /**
       * 画像
       */
      image: string;

      /**
       * タイトル
       */
      title: string;

      /**
       * 説明文
       */
      content: string;
    }[];
  };
  /**
   * 現状はOMOだけに使用されている。
   * CMS管理にするかという話も上がったが、他施設では使用していないのでハードコードで管理している
   * */
  policy?: HotelBrandPolicy;
  /** お知らせ */
  news: SpecialPagePriority[];
}

export interface HotelBrandDetailInfoLS
  extends Omit<
    HotelBrandDetailInfo,
    | 'meta'
    | 'mainLogo'
    | 'catchCopy'
    | 'keyVisual'
    | 'lead'
    | 'conceptMovie'
    | 'features'
    | 'policy'
  > {
  meta: {
    siteName: LanguageDataMap<string>;
    description: LanguageDataMap<string>;
    image: LanguageDataMap<string>;
  };
  useHeaderLogo: boolean;
  mainLogo: LanguageDataMap<
    | string
    | {
        url: string;
        sprite?: {
          url: string;
          width: number;
          height: number;
          webp?: boolean;
          fallback?: string;
          fps?: number;
          duration?: number;
        };
      }
  >;
  catchCopy: LanguageDataMap<string>;
  keyVisual: {
    image: string;
    alt: LanguageDataMap<string>;
  };
  lead: LanguageDataMap<string>;
  features: {
    title: LanguageDataMap<string>;
    items: {
      image: string;
      title: LanguageDataMap<string>;
      content: LanguageDataMap<string>;
    }[];
  };
  // これいる？
  policy?: HotelBrandPolicyLS;
}

export function extractHotelBrandDetailInfoLS(
  ls: HotelBrandDetailInfoLS,
  lang: AvailableLanguage,
): HotelBrandDetailInfo {
  const { meta, mainLogo, catchCopy, keyVisual, lead, features, policy } = ls;
  let _logo = mainLogo[lang];
  if (!_logo) {
    throw new Error(`missing mainLogo`);
  }
  if (typeof _logo === 'string') {
    _logo = { url: _logo };
  }

  return {
    ...ls,
    meta: {
      siteName: meta.siteName[lang] as string,
      description: meta.description[lang],
      image: meta.image[lang],
    },
    mainLogo: _logo,
    catchCopy: catchCopy[lang] as string,
    keyVisual: {
      image: keyVisual.image,
      alt: keyVisual.alt[lang] as string,
    },
    lead: lead[lang] as string,
    conceptMovie: null,
    features: {
      title: features.title[lang] as string,
      items: features.items.map(({ image, title, content }) => {
        return {
          image,
          title: title[lang] as string,
          content: content[lang] as string,
        };
      }),
    },
    policy: policy && extractHotelBrandPolicyLS(policy, lang),
  };
}

export interface HotelBrandDetail
  extends HotelBrandInfo,
    HotelBrandDetailInfo {}
